import React, { useState } from "react";
import Register from "../Register";
import { Modal } from "react-bootstrap";
import Countdown from "../Countdown/countdownTimer";

const Home = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const toggleRegisterModal = () => {
    setShowRegisterModal(!showRegisterModal);
  };
  let countdowndate = "April 28, 2023 06:00:00";

  return (
    <div style={{ backgroundImage: "url('/bg.png')" }}>
      <div className="wrapper">
        <div className="container">
          <header className="row justify-center">
            <div className="col-12 col-md-3">
              <div className="logo">
                <img src="/logo.png" alt="KS10" />
              </div>
            </div>
            <div className="col">
              <ul className="menu">
                <li>
                  <div>Home</div>
                </li>
                <li>
                  <div>About Event</div>
                </li>
                <li>
                  <div>Register</div>
                </li>
              </ul>
            </div>
            <div className="timer col-md-5 col-lg-4">
              <Countdown targetDate={countdowndate} />
            </div>
          </header>
        </div>
      </div>
      <div>
        <div className="container hero">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-8">
              <div className="main-section-wrapper">
                <div className="title-large tournament" id="tournament">
                  The Tournament
                </div>
                <div className="title-large tournament" id="next">
                  Are You Next?
                </div>
                <div className="main-text">
                  Are you ready for your team to show their true talent? The
                  Tournament is competitive, is education, and will get your
                  team recognized! This exciting opportunity for young players
                  to showcase their skills and love for the sport. With games,
                  drills, and skills training, kids of all skill levels can
                  participate and improve their abilities while having fun.
                </div>
                <div className="main-text">
                  *** Register Now before: April 28, 2023 ***
                </div>
                <div className="main-buttons">
                  <button
                    className="btn btn-primary me-3"
                    onClick={toggleRegisterModal}
                  >
                    Register
                  </button>
                  <button className="btn btn-secondary">Learn more</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-8"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="kordell">
                <div className="kordell-img">
                  <img src="/kordell-stewart.jpg" alt="Kordell Stewart" />
                </div>
                <div className="kordell-content">
                  <div className="kordell-content-title">
                    <div className="kordell-content-title-small">
                      Sponsored by
                    </div>
                    Kordell Stewart
                  </div>
                  <div className="kordell-content-desc">
                    Kordell Stewart is a former American football quarterback
                    who played in the NFL for 11 seasons from 1995 to 2005. He
                    was known for his versatility on the field and his ability
                    to play multiple positions, including quarterback, wide
                    receiver, and punt returner. Stewart played most of his
                    career with the Pittsburgh Steelers and was named to the Pro
                    Bowl in 2001. After retiring from football, he has worked as
                    a television analyst and hosted a radio show.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" fullscreen centered show={showRegisterModal}>
        <Modal.Body>
          <Register onCloseClick={() => setShowRegisterModal(false)} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Home;
