import React, { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "react-bootstrap";
import "./stripe.css";
import {
  PaymentElement,
  // LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const Register = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [show, setShow] = useState(false);
  // const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [isValidated, setIsvalidated] = useState(false);
  const modalRef = useRef();

  const [inputState, setInputState] = useState({
    teamName: "",
    coachFirstName: "",
    coachLastName: "",
    coachEmail: "",
    coachPhoneNumber: "",
    league: "",
    teamCity: "",
    teamState: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputState({ ...inputState, [name]: value });
  };
  // const validateFields = () => {
  //   if (
  //     teamName !== "" ||
  //     coachFirstName !== "" ||
  //     coachLastName !== "" ||
  //     coachEmail !== "" ||
  //     coachPhoneNumber !== "" ||
  //     teamCity !== "" ||
  //     league !== "" ||
  //     teamState !== ""
  //   ) {
  //     setIsvalidated(true);
  //   } else {
  //     setIsvalidated(false);
  //     return false;
  //   }
  // };
  console.log(props.amount);
  const handleShow = (e) => {
    e.preventDefault();

    // validateFields();
    if (
      teamName !== "" ||
      coachFirstName !== "" ||
      coachLastName !== "" ||
      coachEmail !== "" ||
      coachPhoneNumber !== "" ||
      teamCity !== "" ||
      league !== "" ||
      teamState !== ""
    ) {
      setShow(true);
      setTimeout(() => {
        document
          .getElementById("submitForm")
          ?.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    } else {
      setShow(false);
      toast.error("please make sure to fill all fields");
    }
  };
  const {
    teamName,
    coachFirstName,
    coachLastName,
    coachEmail,
    coachPhoneNumber,
    league,
    teamCity,
    teamState,
  } = inputState;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (response.error) {
      setMessage(response.error.message);
    } else {
      setMessage("Payment Succeeded!");
      console.log("message", message);
      const url = "https://ks10sportsacademy.herokuapp.com";

      fetch(`${url}/signup`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          teamName,
          coachFirstName,
          coachLastName,
          coachEmail,
          coachPhoneNumber,
          league,
          teamCity,
          teamState,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.message === "mail exists") {
            toast.error("Mail exists");
            return false;
          } else if (res.success) {
            toast.success("user created successfully, please check you email");
            console.log(res.user, res.token);
            document
              .getElementById("main-form")
              ?.scrollIntoView({ behavior: "smooth" });
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else if (res.error) {
            console.log("error", res.err);
            toast.error(res.err);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#87BBFD",
        },
      },
      invalid: {
        iconColor: "#FFC7EE",
        color: "#FFC7EE",
      },
    },
  };

  return (
    <div className="form">
      <div className="container">
        <form className="form-section" id="main-form">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="cls-btn"
                  role="button"
                  onClick={props.onCloseClick}
                >
                  <i className="bi bi-x-circle"></i>
                </div>
                <h2 className="register-title">Register Your Team</h2>
                <fieldset>
                  <legend className="">Team Details</legend>
                  <div className="row gx-3 gy-3">
                    <div className="col-12">
                      <Form.FloatingLabel
                        controlId="teamName"
                        label="Team Name"
                      >
                        <Form.Control
                          type="text"
                          name="teamName"
                          onChange={handleChange}
                          value={teamName}
                          placeholder="Team Name"
                        />
                      </Form.FloatingLabel>
                    </div>
                    <div className="col-12">
                      <Form.FloatingLabel controlId="league" label="League">
                        <select
                          className="form-select"
                          name="league"
                          onChange={handleChange}
                          value={league}
                        >
                          <option value="">Select League</option>
                          <option value="8U">8U</option>
                          <option value="10U">10U</option>
                          <option value="12U">12U</option>
                        </select>
                      </Form.FloatingLabel>
                    </div>
                    <div className="col-12 col-lg-6">
                      <Form.FloatingLabel controlId="teamCity" label="City">
                        <Form.Control
                          type="text"
                          name="teamCity"
                          onChange={handleChange}
                          value={teamCity}
                          placeholder="City"
                        />
                      </Form.FloatingLabel>
                    </div>
                    <div className="col-12 col-lg-6">
                      <Form.FloatingLabel controlId="teamState" label="State">
                        <select
                          className="form-select"
                          name="teamState"
                          onChange={handleChange}
                          value={teamState}
                          placeholder="State"
                        >
                          <option value="">Select State</option>
                          <option value="Texas">Texas</option>
                          <option value="Washington">Washington</option>
                        </select>
                      </Form.FloatingLabel>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Coach Details</legend>
                  <div className="row gx-3 gy-3">
                    <div className="col-12 col-lg-3">
                      <Form.FloatingLabel
                        controlId="coachFirstName"
                        label="Coach First name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Coach First Name"
                          onChange={handleChange}
                          value={coachFirstName}
                          name="coachFirstName"
                        />
                      </Form.FloatingLabel>
                    </div>
                    <div className="col-12 col-lg-3">
                      <Form.FloatingLabel
                        controlId="coachLastName"
                        label="Coach Last name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Coach Last Name"
                          onChange={handleChange}
                          value={coachLastName}
                          name="coachLastName"
                        />
                      </Form.FloatingLabel>
                    </div>
                    <div className="col-12 col-lg-3">
                      <Form.FloatingLabel
                        controlId="coachEmail"
                        label="Coach Email"
                      >
                        <Form.Control
                          type="email"
                          placeholder="Coach Email"
                          onChange={handleChange}
                          value={coachEmail}
                          name="coachEmail"
                        />
                      </Form.FloatingLabel>
                    </div>
                    <div className="col-12 col-lg-3">
                      <Form.FloatingLabel
                        controlId="coachNumber"
                        label="Coach Phone Number"
                      >
                        <Form.Control
                          type="tel"
                          placeholder="Coach Phone Number"
                          onChange={handleChange}
                          value={coachPhoneNumber}
                          name="coachPhoneNumber"
                        />
                      </Form.FloatingLabel>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="col-12">
                <button className="btn btn-primary" onClick={handleShow}>
                  Add Payment
                </button>
              </div>
            </div>
          </div>
        </form>
        {show && (
          <form id="payment-form" ref={modalRef}>
            {/* <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
      /> */}
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <button
              disabled={isLoading || !stripe || !elements}
              id="submitForm"
              onClick={handleSubmit}
            >
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  // `Pay $ ${props.amount}`
                  `pay $300`
                )}
              </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </form>
        )}
        <ToastContainer
          position="top-right"
          hideProgressBar={false}
          autoClose={4000}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          rtl={false}
        />
      </div>
    </div>
  );
};

export default Register;
